
	import { Component, Prop, Vue } from "vue-property-decorator";

	import Progress from "../Progress.vue";
	import Application from "./Application.vue";
	import type { QuickApplicationModel } from "@/models/payments/Scan2Pay";


	@Component({ components: { Progress, Application } })
	export default class extends Vue {
		@Prop() public data!: QuickApplicationModel;

		protected edit: boolean = false;
	}
