
	import { Component, Prop, Vue } from "vue-property-decorator";

	import Mode from "./Mode.vue";
	import type { TokenPayApplicationModel } from "@/models/payments/Token";

	@Component({ components: { Mode } })
	export default class extends Vue {
		@Prop() public data!: TokenPayApplicationModel;
	}
