
	import { Component, Vue } from "vue-property-decorator";

	import { FeatureStatus } from "@common/models/merchant";
	import Step2 from './Step2.vue';
	import Step3 from './Step3.vue';
	import Step4 from './Step4.vue';
	import type { TokenPayApplicationModel } from "@/models/payments/Token";

	@Component({ components: { Step2, Step3, Step4 } })
	export default class extends Vue {
		protected data: TokenPayApplicationModel | null = null;
		protected code = FeatureStatus;

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = await post<TokenPayApplicationModel>("/api/TokenPay");
			// console.log(this.data);
		}
	}
