
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import { share } from "@common/jsInterface";

	import type { OutletShareModel } from "@common/models/merchant";
	import type Toast from "@common/components/widget/Toast.vue";

	@Component
	export default class extends Vue {
		$refs!: {
			toast: Toast;
		};

		@Prop(String) public id!: string;

		@Inject() public readonly confirm!: ConfirmMethod;
		@Inject() public readonly alert!: AlertMethod;

		protected data: OutletShareModel | null = null;

		protected get url(): string {
			if(!this.data) return "";
			return location.origin + "/link/" + this.data.Link;
		}

		mounted(): void {
			this.load();
		}

		private async load() {
			try {
				this.data = await post<OutletShareModel>(
					"/api/Merchant/ShareOutlet",
					{
						Id: this.id,
					}
				);
				await this.checkData();
			} catch(e: unknown) {
				if(e instanceof Error) await this.alert(e.message, undefined, "error");
				this.$router.back();
			}
		}

		protected detail(): void {
			this.$router.push("/merchant/edit");
		}

		protected onCopy(): void {
			this.$refs.toast.show();
		}

		protected share(): void {
			share(this.url, undefined, this.data!.Name + " 裝置配對");
		}

		protected async update(): Promise<void> {
			if(
				!await this.confirm(
					"已分享的配對連結將會失效，確定更新連結？",
					undefined,
					"danger"
				)
			) return;
			this.data = null;
			this.data = await post<OutletShareModel>("/api/Merchant/UpdateShare", {
				Id: this.id,
			});
			await this.checkData();
		}

		private async checkData(): Promise<void> {
			if(this.data == null) {
				await this.alert(
					"指定的門市不存在或者沒有管理權限",
					undefined,
					"error"
				);
				this.$router.back();
			}
		}

		protected match(): void {
			this.$router.push("/merchant/match/accept/" + this.data!.Link);
		}
	}
