
	import { Component, Prop, Vue } from "vue-property-decorator";

	const TIMEOUT = 5000;

	@Component
	export default class extends Vue {
		@Prop(String) public id!: string;

		protected ok: boolean | null = null;

		mounted(): void {
			post<boolean>("/api/login/verify/", { id: this.id }, TIMEOUT).then(v => this.ok = v);
		}
	}
