
	import { Component, Prop, Vue } from "vue-property-decorator";

	import Progress from "../Progress.vue";
	import Application from "./Application.vue";
	import type { TokenPayApplicationModel } from "@/models/payments/Token";


	@Component({ components: { Progress, Application } })
	export default class extends Vue {
		@Prop() public data!: TokenPayApplicationModel;

		protected edit: boolean = false;
	}
