
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import { FeatureStatus } from "@common/models/merchant";
	import { TEN_SECONDS } from "@common/communication";
	import AccountForm from "../components/AccountForm.vue";
	import MerchantForm from "../components/MerchantForm.vue";
	import { CodeName } from "./Index.vue";
	import type { QuickApplicationModel } from "@/models/payments/Scan2Pay";
	import type { LeapaAccountModel } from "@/models/Info";
	import type { MerchantEditModel } from "@common/models/merchant";

	@Component({ components: { AccountForm, MerchantForm } })
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public data!: QuickApplicationModel;
		@Prop(Boolean) public applying?: boolean;

		protected account: LeapaAccountModel | null = null;
		protected merchant: MerchantEditModel | null = null;

		mounted(): void {
			post<LeapaAccountModel>('/api/Merchant/GetAccount').then(m => this.account = m);
			post<MerchantEditModel>('/api/Scan2Pay/Edit', { CodeName }).then(m => {
				// console.log(m.Lock);
				this.merchant = m;
			});
		}

		protected async send(): Promise<void> {
			if(this.applying) return this.save();
			if(this.data.Status != FeatureStatus.NotApplied) throw new Error("狀態錯誤");
			await post<boolean>('/api/Scan2Pay/Apply', {
				CodeName,
				Merchant: this.merchant,
				Model: this.data,
			}, TEN_SECONDS);
			await this.alert("申請成功", undefined, 'success');
			this.$router.back();
		}

		protected async save(): Promise<void> {
			if(this.data.Status != FeatureStatus.NotApplied && this.data.Status != FeatureStatus.Applying) throw new Error("狀態錯誤");
			await post<boolean>('/api/Scan2Pay/Save', {
				CodeName,
				Merchant: this.merchant,
				Model: this.data,
			}, TEN_SECONDS);
			await this.alert("暫存成功", undefined, 'success');
			this.$router.back();
		}
	}
