
	import { Component, Prop, Vue } from "vue-property-decorator";

	import type { MerchantKioskDetailModel } from "@common/models/kiosk";

	@Component
	export default class Device extends Vue {
		@Prop() public data!: MerchantKioskDetailModel;

		protected get name(): string {
			return this.data.Terminal?.Name ?? "待命名";
		}
	}
