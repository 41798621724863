
	import { Component, Inject, Vue } from "vue-property-decorator";

	import { MerchantType } from "@common/models/merchant";
	import CircleStep from "@/components/CircleStep.vue";
	import Status from "./components/Status.vue";
	import type { LeapAppMerchantModel } from "@common/models/merchant";

	@Component({
		components: { Status, CircleStep },
	})
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		protected data: LeapAppMerchantModel | null = null;

		mounted(): void {
			this.load();
		}

		protected get isPaymentApplicant(): boolean {
			return this.data != null && Boolean(this.data.MerchantType & MerchantType.PaymentApplicant);
		}

		protected get title(): string {
			if(this.isPaymentApplicant) return "支付服務";
			return "支付申請";
		}

		private async load() {
			this.data = null;
			this.data = await post<LeapAppMerchantModel>("/api/Merchant");
		}

		protected has(codename: string): boolean {
			let path = "/merchant/payment/" + codename;
			let resolve = this.$router.resolve(path);
			return resolve.route.name != '404';
		}

		protected go(codename: string): void {
			let path = "/merchant/payment/" + codename;
			let resolve = this.$router.resolve(path);
			if(resolve.route.name != '404') {
				this.$router.push(path);
			}
		}
	}
